import React from "react";
import { BulkSelectionOptions } from "@ax/components";
import { IColumn, IQueryValue } from "@ax/types";
import { usePermission } from "@ax/hooks";
import TableHeader from "./TableHeader";

const BulkHeader = (props: IProps): JSX.Element => {
  const {
    showBulk,
    checkState,
    bulkDelete,
    bulkPublish,
    bulkUnpublish,
    selectItems,
    selectAllItems,
    totalItems,
    isScrolling,
    isStructuredData,
    sortItems,
    filterItems,
    sortedListStatus,
    isEditable,
    filterValues,
    categoryColumns,
    columns,
    setColumns,
    isGlobalPages,
    siteID,
    maxColumns,
    exportAction,
  } = props;

  const isAllowedToDeletePage = usePermission("content.deletePages");
  const isAllowedToPublishPages = usePermission("content.publishUnpublishPages");

  const deleteAction = {
    icon: "delete",
    text: "delete",
    action: bulkDelete,
  };

  const actions = isAllowedToPublishPages
    ? [
        {
          icon: "upload-pending",
          text: "publish",
          action: bulkPublish,
        },
        {
          icon: "offline",
          text: "unpublish",
          action: bulkUnpublish,
        },
      ]
    : [];

  const bulkActions = isEditable && isAllowedToDeletePage ? [deleteAction, ...actions] : actions;

  return showBulk ? (
    <BulkSelectionOptions
      isScrolling={isScrolling}
      checkState={checkState}
      actions={bulkActions}
      selectItems={selectItems}
      totalItems={totalItems}
      exportAction={exportAction}
    />
  ) : (
    <TableHeader
      isScrolling={isScrolling}
      isStructuredData={isStructuredData}
      selectAllItems={selectAllItems}
      checkState={checkState}
      sortItems={sortItems}
      filterItems={filterItems}
      sortedListStatus={sortedListStatus}
      totalItems={totalItems}
      filterValues={filterValues}
      categoryColumns={categoryColumns}
      columns={columns}
      setColumns={setColumns}
      isGlobalPages={isGlobalPages}
      siteID={siteID}
      maxColumns={maxColumns}
    />
  );
};

interface IProps {
  showBulk: boolean;
  checkState: any;
  bulkDelete: () => void;
  bulkPublish: () => void;
  bulkUnpublish: () => void;
  selectItems: () => void;
  selectAllItems: () => void;
  totalItems: number;
  isScrolling: boolean;
  isStructuredData: boolean;
  sortItems: (orderPointer: IQueryValue[], isAscending: boolean) => void;
  filterItems: (filterPointer: string, filtersSelected: IQueryValue[]) => void;
  sortedListStatus: any;
  isEditable?: boolean | null;
  filterValues: any;
  categoryColumns: any[];
  columns: IColumn[];
  setColumns: (columns: IColumn[]) => void;
  maxColumns: { value: number; text: string };
  isGlobalPages: boolean;
  siteID: number;
  exportAction?(formats: (number | string)[]): void;
}

export default BulkHeader;
