import React, { memo, useRef, useState } from "react";
import { schemas } from "components";
import { useTheme } from "styled-components";

import { useAdaptiveText, useModal, usePermission } from "@ax/hooks";
import { getHumanLastModifiedDate, getTemplateDisplayName, slugify } from "@ax/helpers";
import { IPage, ISite, ISavePageParams, ICheck, IColumn, IPageLanguage, IDataPack } from "@ax/types";
import { pageStatus, ISetCurrentPageIDAction } from "@ax/containers/PageEditor/interfaces";
import {
  CheckField,
  FieldsBehavior,
  FloatingMenu,
  Icon,
  Flag,
  LanguageMenu,
  Modal,
  Tag,
  Tooltip,
  CategoryCell,
} from "@ax/components";

import { DeleteModal, CopyModal } from "../atoms";

import * as S from "./style";

const PageItem = (props: IPageItemProps): JSX.Element => {
  const {
    functions,
    item,
    activatedTemplates,
    toggleToast,
    setRemovedPage,
    columns,
    categoryColumns,
    categoryColors,
    addCategoryColors,
    dataPacks,
    sites,
    skipReview,
  } = props;
  const { isSelected, siteLanguages, page, lang, isDuplicable } = item;
  const {
    onClick,
    onCheck,
    deletePage,
    getSiteContent,
    setHistoryPush,
    updatePageStatus,
    setCurrentPageID,
    languageActions,
    duplicatePage,
    validatePage,
    getPage,
    removePageFromSite,
    deleteBulk,
    getDataPack,
    setTemplateInstanceError,
    toggleCopiedToast,
  } = functions;
  const { locale } = lang;
  const {
    pageLanguages,
    isHome,
    fullPath,
    canBeUnpublished,
    origin,
    manuallyImported,
    templateId,
    structuredDataContent,
    liveStatus,
  } = page;

  const displayName = getTemplateDisplayName(templateId);

  const initValue = { title: "", slug: "" };
  const [site, setSite] = useState(null);
  const [modalState, setModalState] = useState(initValue);
  const [deleteAllVersions, setDeleteAllVersions] = useState(false);
  const { isOpen, toggleModal } = useModal();
  const { isOpen: isRemoveOpen, toggleModal: toggleRemoveModal } = useModal();
  const { isOpen: isUnpublishOpen, toggleModal: toggleUnpublishModal } = useModal();
  const { isOpen: isDeleteOpen, toggleModal: toggleDeleteModal } = useModal();
  const { isOpen: isCopyOpen, toggleModal: toggleCopyModal } = useModal();

  const nameCellRef = useRef<HTMLDivElement>(null);
  const theme: any = useTheme();
  const nameCellPadding = Number(theme.spacing.s.slice(0, -2));
  const title = useAdaptiveText(nameCellRef, page.title, nameCellPadding);
  const path = useAdaptiveText(nameCellRef, fullPath.page, nameCellPadding);

  const isAllowedToDuplicatePages = usePermission("content.duplicatePages");
  const isAllowedToPublishPages = usePermission("content.publishUnpublishPages");
  const isAllowedToCreatePages = usePermission("content.createPages");
  const isAllowedToCopyPage = usePermission("content.copyPageInOtherSite");
  const isAllowedToDeletePage = usePermission("content.deletePages");
  const isAllowedToDeletePublishedPage = usePermission("content.deletePublishedPages");
  const isAllowedToEditContentPage = usePermission("content.editContentPages");

  const currentTemplateDataPacks = schemas.templates[templateId].dataPacks;

  const isCopyable = !currentTemplateDataPacks;
  const isGlobal = origin === "GLOBAL";
  const isTranslated = pageLanguages.length > 1;
  const activeColumns = columns.filter((col) => col.show).map((col) => col.id);
  const API_URL = process.env.REACT_APP_API_ENDPOINT;
  const isPublished = liveStatus.status === pageStatus.PUBLISHED || liveStatus.status === pageStatus.UPLOAD_PENDING;
  const isAllowedToDelete = (isPublished && isAllowedToDeletePublishedPage) || (!isPublished && isAllowedToDeletePage);

  const publishedTooltip: any = {
    active: "Live",
    "upload-pending": "Publication pending",
    offline: "Offline",
    "offline-pending": "Offline pending",
    modified: "Live & Modified",
  };

  const setRoute = (path: string) => setHistoryPush(path, true);

  const goToPage = async () => {
    const pageID = page.haveDraftPage ? page.haveDraftPage : page.id;
    setCurrentPageID(pageID);

    const { templateId } = item.page;
    const dataPack = dataPacks.find((pack) => pack.templates.some((template) => template.id === templateId));
    dataPack && (await getDataPack(dataPack.id));

    setRoute("pages/editor");
  };

  const editLivePage = () => {
    setCurrentPageID(page.id);
    setRoute("pages/editor");
  };

  const handleOnChange = (value: ICheck) => onCheck(value);

  const checkSeoDone = (value: string | boolean) => {
    if (typeof value === "string") {
      value = value.trim();
    }
    if (value) {
      return (
        <S.StyledDone>
          <Icon name="done" />
        </S.StyledDone>
      );
    }
    return (
      <S.StyledClose>
        <Icon name="close" />
      </S.StyledClose>
    );
  };

  const SeoItems = {
    Index: () => <S.StyledSeo>{checkSeoDone(page.isIndexed)}Indexed</S.StyledSeo>,
    Title: () => <S.StyledSeo>{checkSeoDone(page.metaTitle)}Title</S.StyledSeo>,
    Description: () => <S.StyledSeo>{checkSeoDone(page.metaDescription)}Description</S.StyledSeo>,
  };

  const SeoTitleMenu = () => {
    if (page.metaTitle) {
      return (
        <FloatingMenu Button={SeoItems.Title}>
          <S.FloatingSeo>{page.metaTitle}</S.FloatingSeo>
        </FloatingMenu>
      );
    }
    return SeoItems.Title();
  };

  const SeoDescriptionMenu = () => {
    if (page.metaDescription) {
      return (
        <FloatingMenu Button={SeoItems.Description}>
          <S.FloatingSeo>{page.metaDescription}</S.FloatingSeo>
        </FloatingMenu>
      );
    }
    return SeoItems.Description();
  };

  const checkWorkflowStatus = () => {
    if (page.workflowStatus) {
      return <Tag type="status" color={page.workflowStatus.color} text={page.workflowStatus.text} />;
    }
  };

  const handleDeleteItem = async () => {
    const allPageVersions = pageLanguages.map((lang: IPageLanguage) => lang.pageId);
    deleteAllVersions ? deleteBulk(allPageVersions) : deletePage();
    toggleDeleteModal();
  };

  const handleRemoveItemFromSite = async () => {
    await removePageFromSite(page.id).then((removed: boolean) => {
      if (removed && page.originalGlobalPage) {
        setRemovedPage(page.originalGlobalPage);
        toggleToast();
      }
      toggleRemoveModal();
    });
  };

  const publishPage = async () => {
    await getPage(page.id);
    const isValidated = skipReview ? true : await validatePage(true);

    if (isValidated) {
      await updatePageStatus([page.id], pageStatus.UPLOAD_PENDING, true);
      await getSiteContent();
    }
  };

  const unpublishPage = async () => {
    await updatePageStatus([page.id], pageStatus.OFFLINE_PENDING, true);
    await getSiteContent();
  };

  const cancelPublishPage = async () => {
    await updatePageStatus([page.id], pageStatus.OFFLINE, true);
    await getSiteContent();
  };

  const getSelectedPageLanguage = (language: any) => pageLanguages.find((lang: any) => lang.languageId === language.id);

  const handleLanguage = (language: any) => () => {
    if (!languageActions || !languageActions.setLanguage) return;

    const { locale, id } = language;

    const lang = {
      locale,
      id,
    };

    languageActions.setLanguage(lang);

    const selectedPageLanguage = getSelectedPageLanguage(language);

    selectedPageLanguage
      ? setCurrentPageID(selectedPageLanguage.pageId)
      : languageActions.createNewTranslation && languageActions.createNewTranslation(true);

    setHistoryPush("pages/editor", true);
  };

  const getCurrentPageLanguages = () => {
    const languages: any[] = [];

    pageLanguages.forEach(
      (pageLang: any) =>
        siteLanguages &&
        siteLanguages.forEach((siteLang) => {
          if (siteLang.id === pageLang.languageId) {
            languages.push(siteLang);
          }
        })
    );

    return languages;
  };

  const handleDuplicatePage = () => {
    setTemplateInstanceError({ error: false, templateName: "" });
    if (isDuplicable) {
      duplicatePage(page.id, modalState).then(() => {
        toggleModal();
        setRoute("pages/editor");
      });
    } else {
      toggleModal();
      setModalState({ title: "", slug: "" });
      setTemplateInstanceError({ error: true, templateName: displayName });
    }
  };

  const mainModalAction = {
    title: "Duplicate",
    onClick: handleDuplicatePage,
    disabled: !modalState.title.trim() || !modalState.slug.trim(),
  };
  const secondaryModalAction = { title: "Cancel", onClick: toggleModal };

  const currentLanguages = getCurrentPageLanguages();

  const isTemplateActivated = activatedTemplates.some((temp: any) => temp.id === templateId);

  let availableLanguages = siteLanguages;

  if (!isTemplateActivated || isGlobal || !isAllowedToCreatePages) {
    const pageLanguagesIDs = pageLanguages.map((language: any) => language.languageId);
    availableLanguages = siteLanguages && siteLanguages.filter((language) => pageLanguagesIDs.includes(language.id));
  }

  const languageMenu = () => (
    <LanguageMenu
      language={locale}
      availableLanguages={availableLanguages}
      setLanguage={handleLanguage}
      currentLanguages={currentLanguages}
    />
  );

  const FlagsButton = () => (
    <S.FlagsWrapper onClick={onClick}>
      {currentLanguages.slice(0, 2).map((pageLanguage: any, i: number) => (
        <Flag key={`${pageLanguage.language}${i}`} name={pageLanguage.locale} size="15" />
      ))}
      <span>({currentLanguages.length})</span>
    </S.FlagsWrapper>
  );

  const handleNameChange = (value: string) => {
    setModalState({ title: value, slug: slugify(value) });
  };

  const handleSlugChange = (value: string) => {
    setModalState({ ...modalState, slug: value });
  };

  const deleteDisabled = isGlobal && (!manuallyImported || canBeUnpublished === false);
  const deleteHelpText =
    isGlobal && !manuallyImported
      ? "This page has been provided by subscription. You cannot remove it."
      : canBeUnpublished === false
      ? "This is the canonical site of the page. You cannot remove it."
      : null;

  let menuOptions: IPageOption[] = [];

  if (isAllowedToDelete) {
    menuOptions.push({
      label: isGlobal ? "Remove from site" : "delete",
      icon: "delete",
      action: isGlobal ? toggleRemoveModal : toggleDeleteModal,
      disabled: deleteDisabled,
      helpText: deleteHelpText,
    });
  }

  if (isCopyable && isAllowedToCopyPage) {
    menuOptions.unshift({
      label: "Copy page in another site",
      icon: "copy",
      action: toggleCopyModal,
    });
  }

  if (!isGlobal && isAllowedToDuplicatePages) {
    menuOptions.unshift({
      label: "duplicate",
      icon: "duplicate",
      action: toggleModal,
    });
  }

  const getPublishItem = (status: string, canBeUnpublished: boolean) => {
    const helpText =
      canBeUnpublished === false ? "This is the canonical site of the page. You cannot unpublish it." : null;
    const disabled = canBeUnpublished === false;

    switch (status) {
      case pageStatus.OFFLINE:
      case pageStatus.OFFLINE_PENDING:
        return {
          label: "publish",
          icon: "upload-pending",
          action: publishPage,
        };
      case pageStatus.PUBLISHED:
        return {
          label: "unpublish",
          icon: "offline",
          action: page.haveDraftPage ? toggleUnpublishModal : unpublishPage,
          disabled,
          helpText,
        };
      case pageStatus.UPLOAD_PENDING:
        return {
          label: "unpublish",
          icon: "offline",
          action: page.haveDraftPage ? toggleUnpublishModal : cancelPublishPage,
          disabled,
          helpText,
        };
      default:
        return null;
    }
  };

  const viewPage = () => window.open(page.fullUrl || `${API_URL}/page/go/${page.id}`, "_blank");

  const viewOption = {
    label: "View online",
    icon: "View",
    action: viewPage,
  };

  const publishAction = getPublishItem(page.liveStatus.status, canBeUnpublished);

  menuOptions = publishAction && isAllowedToPublishPages ? [publishAction, ...menuOptions] : menuOptions;

  const editOptions = [
    {
      label: "View live",
      icon: "active",
      action: editLivePage,
      color: true,
    },
  ];

  if (isAllowedToEditContentPage) {
    editOptions.unshift({
      label: "Edit draft",
      icon: "modified",
      action: goToPage,
      color: true,
    });
  }

  menuOptions = page.haveDraftPage ? [...editOptions, ...menuOptions] : menuOptions;

  menuOptions = page.liveStatus.status === pageStatus.PUBLISHED ? [viewOption, ...menuOptions] : menuOptions;

  const GlobalMark = () => (
    <Tooltip content="Global Page">
      <S.Mark>G</S.Mark>
    </Tooltip>
  );

  const mainRemoveModalAction = {
    title: "Remove Page",
    onClick: handleRemoveItemFromSite,
  };

  const secondaryRemoveModalAction = { title: "Cancel", onClick: toggleRemoveModal };

  const copyToOtherSite = () => {
    if (site) {
      const siteID = parseInt(site);

      duplicatePage(page.id, null, siteID).then((successEvent: boolean) => {
        if (successEvent === true) {
          toggleCopiedToast();
        }
        toggleCopyModal();
      });
    }
  };

  const secondaryCopyModalAction = {
    title: "Cancel",
    onClick: toggleCopyModal,
  };

  const mainCopyModalAction = { title: "Copy page", onClick: copyToOtherSite, disabled: !site };

  const getLiveStatus = () => (page.haveDraftPage ? "modified" : page.liveStatus.status);

  const mainUnpublishAction = { title: "Ok", onClick: toggleUnpublishModal };

  const mainDeleteModalAction = {
    title: "Delete page",
    onClick: handleDeleteItem,
  };

  const secondaryDeleteModalAction = { title: "Cancel", onClick: toggleDeleteModal };

  const CategoryColumns = categoryColumns.map((col: any) => {
    const type: any = structuredDataContent && structuredDataContent[col.key];
    const categories: string[] = !type
      ? []
      : Array.isArray(type)
      ? type.map((cat: any) => cat.label || cat.title)
      : [type.label || type.title];

    return (
      activeColumns.includes(col.key) && (
        <CategoryCell
          key={col.key}
          categories={categories}
          categoryColors={categoryColors}
          addCategoryColors={addCategoryColors}
        />
      )
    );
  });

  return (
    <>
      <S.PageRow role="rowgroup" selected={isSelected} global={isGlobal}>
        <S.CheckCell role="cell">
          <CheckField name="check" value={page.id} checked={isSelected} onChange={handleOnChange} />
        </S.CheckCell>
        <S.NameCell role="cell" onClick={goToPage} ref={nameCellRef}>
          {isHome && (
            <S.Home>
              <Icon name="home" />
              Home
            </S.Home>
          )}
          <Tooltip
            content={[
              <React.Fragment key="page-title">
                <strong>{page.title}</strong>
                <br />
              </React.Fragment>,
              <React.Fragment key="page-slug">{fullPath.page}</React.Fragment>,
            ]}
            left={0}
            top={1}
            expanded
          >
            <S.Title width={title.width}>{title.text}</S.Title>
            {!isHome && <S.Slug width={path.width}>{path.text}</S.Slug>}
          </Tooltip>
        </S.NameCell>

        {activeColumns.includes("type") && (
          <S.TypeCell role="cell" onClick={goToPage}>
            {displayName}
          </S.TypeCell>
        )}
        {activeColumns.includes("live") && (
          <S.LiveCell role="cell" onClick={goToPage}>
            <Tooltip content={publishedTooltip[getLiveStatus()]}>
              <Icon name={getLiveStatus()} />
            </Tooltip>
          </S.LiveCell>
        )}
        {CategoryColumns}
        {activeColumns.includes("status") && (
          <S.StatusCell role="cell" onClick={goToPage}>
            {checkWorkflowStatus()}
            <S.ModDate>{`Mod. ${getHumanLastModifiedDate(page.modified)}`}</S.ModDate>
          </S.StatusCell>
        )}
        {activeColumns.includes("translation") && (
          <S.TransCell role="cell">
            <FloatingMenu Button={FlagsButton}>{languageMenu()}</FloatingMenu>
          </S.TransCell>
        )}
        {activeColumns.includes("seo") && (
          <S.SeoCell role="cell">
            <SeoItems.Index />
            {SeoTitleMenu()}
            {SeoDescriptionMenu()}
          </S.SeoCell>
        )}
        <S.GlobalCell>{isGlobal && <GlobalMark />}</S.GlobalCell>
        <S.ActionsCell role="cell">
          <S.StyledActionMenu icon="more" options={menuOptions} tooltip="Page actions" />
        </S.ActionsCell>
      </S.PageRow>
      <CopyModal
        isOpen={isCopyOpen}
        toggleModal={() => {
          setSite(null);
          toggleCopyModal();
        }}
        mainModalAction={mainCopyModalAction}
        secondaryModalAction={secondaryCopyModalAction}
        sites={sites}
        site={site}
        setSite={setSite}
      />
      <Modal
        isOpen={isOpen}
        hide={toggleModal}
        title="Duplicate page"
        secondaryAction={secondaryModalAction}
        mainAction={mainModalAction}
        size="M"
      >
        {isOpen ? (
          <S.ModalContent>
            <FieldsBehavior
              fieldType="TextField"
              name="title"
              title="Title"
              mandatory={true}
              value={modalState.title}
              onChange={handleNameChange}
            />
            <FieldsBehavior
              fieldType="TextField"
              name="slug"
              title="Slug"
              mandatory={true}
              value={modalState.slug}
              onChange={handleSlugChange}
            />
          </S.ModalContent>
        ) : null}
      </Modal>
      <Modal
        isOpen={isRemoveOpen}
        hide={toggleRemoveModal}
        title="Remove page from this site?"
        secondaryAction={secondaryRemoveModalAction}
        mainAction={mainRemoveModalAction}
        size="S"
      >
        {isRemoveOpen ? (
          <S.ModalContent>
            <p>
              Are you sure you want to remove <strong>‘{page.title}’</strong> from this site?
              <br />
              This action <strong>cannot be undone</strong>.
            </p>
          </S.ModalContent>
        ) : null}
      </Modal>
      <Modal
        isOpen={isUnpublishOpen}
        hide={toggleUnpublishModal}
        size="S"
        title="Unpublish Modified Page"
        mainAction={mainUnpublishAction}
      >
        {isUnpublishOpen && (
          <S.ModalContent>
            <p>
              There are some saved changes that are not published on this page. To Unpublish this page,{" "}
              <strong>you must publish these changes or discard them.</strong>
            </p>
          </S.ModalContent>
        )}
      </Modal>
      <DeleteModal
        isOpen={isDeleteOpen}
        toggleModal={toggleDeleteModal}
        mainModalAction={mainDeleteModalAction}
        secondaryModalAction={secondaryDeleteModalAction}
        {...{ isTranslated, deleteAllVersions, setDeleteAllVersions, title: page.title }}
      />
    </>
  );
};

interface IPageOption {
  label: string;
  icon: string;
  action: () => void;
  disabled?: boolean;
  helpText?: string | null;
  color?: boolean;
}

interface IPageItemProps {
  item: {
    isSelected: boolean;
    siteLanguages?: any[];
    page: IPage;
    site: ISite;
    lang: { locale: string; id: number };
    isDuplicable: boolean;
  };
  functions: {
    languageActions?: {
      setLanguage?(lang: { locale: string; id: number | null }): void;
      createNewTranslation?(isNewTranslation: boolean): void;
      getContent?(id?: number): void;
    };
    onClick: (e: any) => void;
    onCheck: (e: any) => void;
    deletePage(params?: ISavePageParams, currentLanguage?: string): void;
    getSiteContent(): any;
    setHistoryPush(path: string, isEditor: boolean): void;
    updatePageStatus(ids: number[], status: string, updatedFromList: boolean): Promise<boolean>;
    setCurrentPageID(currentPageID: number | null): ISetCurrentPageIDAction;
    duplicatePage(pageID: number, data: any, siteID?: number): Promise<boolean>;
    removePageFromSite(pageID: number): Promise<boolean>;
    deleteBulk(ids: number[]): void;
    setTemplateInstanceError(error: any): void;
    getDataPack: (id: string) => Promise<void>;
    toggleCopiedToast(): void;
    getPage(pageID?: number, global?: boolean): Promise<void>;
    validatePage(publish?: boolean, browserRef?: any, currentPage?: IPage): Promise<boolean>;
  };
  activatedTemplates: any[];
  toggleToast(): void;
  setRemovedPage(pageID: number): void;
  categoryColumns: any[];
  columns: IColumn[];
  categoryColors: any;
  addCategoryColors(cats: string[]): void;
  dataPacks: IDataPack[];
  sites: ISite[];
  skipReview?: boolean;
}

export default memo(PageItem);
