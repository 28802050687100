import React from "react";

import {
  CheckField,
  TableCounter,
  CustomizeFilters,
  CategoryFilter,
  NameFilter,
  StatusFilter,
  LiveFilter,
  TranslationsFilter,
} from "@ax/components";
import { IColumn, IQueryValue, ISchemaField } from "@ax/types";

import * as S from "./style";

const TableHeader = (props: IProps): JSX.Element => {
  const {
    isScrolling,
    isStructuredData,
    selectAllItems,
    checkState,
    sortItems,
    filterItems,
    sortedListStatus,
    totalItems,
    categoryColumns,
    columns,
    setColumns,
    isGlobalPages,
    filterValues,
    siteID,
    maxColumns,
  } = props;

  const activeColumns = columns.filter((col) => col.show).map((col) => col.id);

  const CategoryColumns =
    isGlobalPages &&
    categoryColumns.map(
      (col) =>
        activeColumns.includes(col.key) && (
          <S.HeaderWrapper key={col.key}>
            <CategoryFilter
              filterItems={filterItems}
              structuredData={col}
              value={filterValues.categories}
              siteID={siteID}
            />
          </S.HeaderWrapper>
        )
    );

  return (
    <S.TableHeader isScrolling={isScrolling}>
      <S.CheckHeader>
        <CheckField
          key="selectAll"
          name="selectAll"
          value="selectAll"
          onChange={selectAllItems}
          checked={checkState.isAllSelected}
          disabled={false}
          error={false}
        />
      </S.CheckHeader>
      {isStructuredData ? (
        <>
          <S.NameWrapper>
            <NameFilter sortItems={sortItems} sortedState={sortedListStatus} />
          </S.NameWrapper>
          {activeColumns.includes("live") && (
            <S.HeaderWrapper>
              <LiveFilter filterItems={filterItems} value={filterValues.liveStatus} hasBasicStatus={true} />
            </S.HeaderWrapper>
          )}
          {CategoryColumns}
          {activeColumns.includes("status") && (
            <S.HeaderWrapper>
              <StatusFilter sortItems={sortItems} sortedState={sortedListStatus} isStructuredData={true} />
            </S.HeaderWrapper>
          )}
          {activeColumns.includes("translation") && (
            <S.HeaderWrapper>
              <TranslationsFilter filterItems={filterItems} value={filterValues.translated} />
            </S.HeaderWrapper>
          )}
        </>
      ) : (
        <>
          <S.NameWrapper>
            <NameFilter sortItems={sortItems} sortedState={sortedListStatus} />
          </S.NameWrapper>

          {activeColumns.includes("type") && <S.TypeHeader>Type</S.TypeHeader>}
          {activeColumns.includes("live") && (
            <S.HeaderWrapper>
              <LiveFilter filterItems={filterItems} value={filterValues.liveStatus} />
            </S.HeaderWrapper>
          )}
          {CategoryColumns}
          {activeColumns.includes("status") && (
            <S.HeaderWrapper>
              <StatusFilter sortItems={sortItems} sortedState={sortedListStatus} />
            </S.HeaderWrapper>
          )}
          {activeColumns.includes("translation") && (
            <S.HeaderWrapper>
              <TranslationsFilter filterItems={filterItems} value={filterValues.translated} />
            </S.HeaderWrapper>
          )}
          {activeColumns.includes("seo") && <S.SeoHeader>SEO</S.SeoHeader>}
          <S.GlobalMark />
        </>
      )}
      <S.ActionsHeader>
        <TableCounter totalItems={totalItems} />
        <CustomizeFilters columns={columns} setColumns={setColumns} value={activeColumns} limit={maxColumns} />
      </S.ActionsHeader>
    </S.TableHeader>
  );
};

interface IProps {
  isScrolling: boolean;
  isStructuredData: boolean;
  totalItems: number;
  selectAllItems: () => void;
  checkState: Record<string, boolean>;
  sortItems: (orderPointer: IQueryValue[], isAscending: boolean) => void;
  filterItems: (filterPointer: string, filtersSelected: IQueryValue[]) => void;
  sortedListStatus: { isAscending: boolean; sortedByDate: boolean; sortedByTitle: boolean; sortedByURL: boolean };
  categoryColumns: ISchemaField[];
  columns: IColumn[];
  setColumns: (columns: IColumn[]) => void;
  maxColumns: { value: number; text: string };
  isGlobalPages: boolean;
  filterValues: any;
  siteID: number;
}

export default TableHeader;
