import React from "react";

import {
  CheckField,
  TableCounter,
  CustomizeFilters,
  CategoryFilter,
  NameFilter,
  StatusFilter,
  SiteFilter,
  LiveFilter,
  TranslationsFilter,
} from "@ax/components";
import { IColumn, IQueryValue, ISchemaField, IStructuredDataQueryValues } from "@ax/types";
import { getGlobalPageTypes } from "@ax/helpers";

import * as S from "./style";

const TableHeader = (props: IProps): JSX.Element => {
  const {
    totalItems,
    selectAllItems,
    isScrolling,
    isFromPage,
    sortItems,
    filterItems,
    filterValues,
    sortedListStatus,
    isAllPages,
    checkState,
    categoryColumns,
    columns,
    setColumns,
    maxColumns,
  } = props;

  const activeColumns = columns.filter((col) => col.show).map((col) => col.id);
  const filterCategories = isFromPage ? filterValues.categories : filterValues.related;

  const CategoryColumns =
    !isAllPages &&
    categoryColumns.map(
      (col) =>
        activeColumns.includes(col.key) && (
          <S.HeaderWrapper key={col.key}>
            <CategoryFilter filterItems={filterItems} value={filterCategories} structuredData={col} siteID={null} />
          </S.HeaderWrapper>
        )
    );

  const globalPageTypes = getGlobalPageTypes();
  const typeFilters = globalPageTypes.map(({ type, title }) => ({ name: type, value: type, title }));
  const allFilter = { name: "all", value: "all", title: "All types" };
  typeFilters.unshift(allFilter);

  return (
    <S.TableHeader isScrolling={isScrolling}>
      <S.CheckHeader>
        <CheckField
          key="selectAll"
          name="selectAll"
          value="selectAll"
          onChange={selectAllItems}
          disabled={false}
          error={false}
          checked={checkState.isAllSelected}
        />
      </S.CheckHeader>
      {isFromPage ? (
        <>
          <S.NameWrapper>
            <NameFilter sortItems={sortItems} sortedState={sortedListStatus} urlSorting={true} />
          </S.NameWrapper>
          {isAllPages && activeColumns.includes("type") && <S.TypeHeader>Type</S.TypeHeader>}
          {activeColumns.includes("site") && (
            <S.HeaderWrapper>
              <SiteFilter filterItems={filterItems} value={filterValues} pointer="filterSites" />
            </S.HeaderWrapper>
          )}
          {activeColumns.includes("live") && (
            <S.HeaderWrapper>
              <LiveFilter filterItems={filterItems} value={filterValues.liveStatus} />
            </S.HeaderWrapper>
          )}
          {CategoryColumns}
          {activeColumns.includes("status") && (
            <S.HeaderWrapper>
              <StatusFilter sortItems={sortItems} sortedState={sortedListStatus} />
            </S.HeaderWrapper>
          )}
          {activeColumns.includes("translation") && (
            <S.HeaderWrapper>
              <TranslationsFilter filterItems={filterItems} value={filterValues.translated} />
            </S.HeaderWrapper>
          )}
          {activeColumns.includes("seo") && <S.SeoHeader>SEO</S.SeoHeader>}
        </>
      ) : (
        <>
          <S.NameWrapper>
            <NameFilter sortItems={sortItems} sortedState={sortedListStatus} />
          </S.NameWrapper>
          {activeColumns.includes("live") && (
            <S.HeaderWrapper>
              <LiveFilter filterItems={filterItems} value={filterValues.liveStatus} hasBasicStatus={true} />
            </S.HeaderWrapper>
          )}
          {CategoryColumns}
          {activeColumns.includes("status") && (
            <S.HeaderWrapper>
              <StatusFilter sortItems={sortItems} sortedState={sortedListStatus} isStructuredData={true} />
            </S.HeaderWrapper>
          )}
          {activeColumns.includes("translation") && (
            <S.HeaderWrapper>
              <TranslationsFilter filterItems={filterItems} value={filterValues.translated} />
            </S.HeaderWrapper>
          )}
        </>
      )}
      <S.ActionsHeader>
        <TableCounter totalItems={totalItems} />
        <CustomizeFilters columns={columns} setColumns={setColumns} value={activeColumns} limit={maxColumns} />
      </S.ActionsHeader>
    </S.TableHeader>
  );
};

interface IProps {
  totalItems: number;
  isScrolling: boolean;
  selectAllItems: () => void;
  isFromPage: boolean;
  sortItems: (orderPointer: IQueryValue[], isAscending: boolean) => void;
  filterItems: (filterPointer: string, filtersSelected: IQueryValue[]) => void;
  sortedListStatus: { isAscending: boolean; sortedByDate: boolean; sortedByTitle: boolean; sortedByURL: boolean };
  isAllPages: boolean;
  filterValues: IStructuredDataQueryValues;
  checkState: Record<string, boolean>;
  categoryColumns: ISchemaField[];
  columns: IColumn[];
  setColumns: (columns: IColumn[]) => void;
  maxColumns: { value: number; text: string };
}

export default TableHeader;
